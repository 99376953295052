<template>
  <div id="presentation" class="container">
    <div class="container " id="informations">
        <!-- <div class="row container"> -->
            <div class="row">
                <div class="col-md-6" >
                    <h3 align="left"> Compétences techniques </h3>
                    <p id="sep"></p>
                    <div class="info tailleFont">
                        <ul>
                            <li> Word , Power point </li>
                            <li>  Langage informatiques : 
                                <ul>
                                    <li> Cpp - C </li>
                                    <li> Java </li>
                                    <li> Javascript </li>
                                    <li> HTML, CSS </li>
                                    <li> PHP </li>
                                    <li> Python </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class=col-md-6>
                    <!-- Gallery -->
<div class="row">
  <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="../assets/word.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />

    <img
      src="../assets/python.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../assets/html_css.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />

    <img
      src="../assets/javascript.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../assets/java.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />

    <img
      src="../assets/cpp.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />
  </div>
</div>
<!-- Gallery -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 compTop">
                    <h3 align="left"> Compétences Linguistiques</h3>
                    <p id="sep"></p>
                    <div class="info tailleFont ">
                        <ul>
                            <li> Anglais : Niveau B1 </li>
                            <li> Espagnol : Comprehension </li>
                            <li> Arabe : Lecture et Ecriture </li>
                            <li> Bèrbère : Courremment </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- Gallery -->
<div class="row " align="center">
  <div class="col-lg-4 col-md-12 mb-4 mb-lg-0 compTopImg">
    <img
      src="../assets/uk.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />

    <img
      src="../assets/rif.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0 compTopImg">
    <img
      src="../assets/esp.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />

    <img
      src="../assets/maroc.png"
      class="w-100 shadow-1-strong rounded mb-4"
      alt=""
    />
  </div>

</div>
<!-- Gallery -->
                </div>
            </div>
            <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
    name:'Compétences',
    data(){
        return{

        }
    }
}
</script>

<style>

</style>